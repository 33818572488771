
import { computed, defineComponent, onRenderTracked, onUpdated, reactive, ref } from "vue";
import { LoadFilterObjects } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import Avatar from "@/components/base/common/Avatar.vue";
import AuditAction from "@/views/audit/AuditAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Panel from "@/components/base/panel/Panel.vue";
import Pagination from "@/components/base/paging/Pagination.vue";

export default defineComponent({
  name: "Audits",
  components: {Pagination, Panel, DateTimeFormat, AuditAction, Avatar},
  props: {
    objectType: {type: String},
    objectId: {type: String},
  },
  setup(props) {
    const state = reactive({
      show: false,
      audit: {}
    })
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    const showAudit = (audit) => {
      state.audit = audit;
      state.show = true
    }
    const hideAudit = () => {
      state.show = false;
    }
    const filterObject = ref({objectType: props.objectType, objectId: props.objectId})
    const auditPage = computed(() => store.state.AuditModule.auditPage)
    return {
      ...LoadFilterObjects(Actions.LOAD_AUDITS, filterObject.value, []),
      auditPage,
      state,
      showAudit,
      filterObject,
      hideAudit,
    }
  },
  watch: {
    objectId(objectId) {
      this.filterObject.objectId = objectId;
      this.updateFilterObject(this.filterObject)
    }
  }
})
