import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_flatPickr = _resolveComponent("flatPickr")!

  return (_openBlock(), _createBlock(_component_flatPickr, {
    modelValue: _ctx.selected,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
    config: _ctx.config,
    class: _normalizeClass(_ctx.clazz ),
    onOnChange: _ctx.onSelect,
    onOnClose: _ctx.onSelect,
    placeholder: "MM/dd/yyyy"
  }, null, 8, ["modelValue", "config", "class", "onOnChange", "onOnClose"]))
}