
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "CustomerDocumentStatus",
  props: {
    status: {}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''});

    const build = (status) => {
      switch (status) {
        case 'NA':
        case 'REQUESTED':
          data.value = {name: 'Requested', clazz: 'badge-secondary'}
          break
        case 'UPLOADED':
          data.value = {name: 'Uploaded', clazz: 'badge-primary'}
          break
        case 'ACCEPTED':
          data.value = {name: 'Accepted', clazz: 'badge-success'}
          break
        case 'REJECTED':
          data.value = {name: 'Rejected', clazz: 'badge-danger'}
          break
      }
    }
    build(props.status)

    watch(() => props.status, cb => {
      build(cb);
    })

    return {
      data
    }
  }
})
