import {BaseService} from "@/core/services/BaseService";

class FormService extends BaseService<any> {
  endpoint = "/form"

  async download(id) {
    return await this.http().get(this.endpoint + "/" + id + "/download",
      {responseType: "blob"}).then(res => {
      return res;
    })
  }

  async prePopulate(id, type, data) {
    return await this.http().post(this.endpoint + "/" + id + "/pre-populate/" + type, data).then(res => {
      return res.data;
    })
  }

  async validate(data) {
    return this.http().post(this.endpoint + "/validate", data).then(res => {
      return res.data;
    })
  }

  async secureLink(id) {
    return this.http().get(this.endpoint + "/" + id + "/secure-link").then(res => {
      return res.data;
    })
  }
}

export default new FormService();