
import {computed, defineComponent} from 'vue'
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import DatePicker from "@/components/base/datetime/DatePicker.vue";
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";

export default defineComponent({
  name: "CustomFormField",
  components: { BaseSelect, DatePicker, FormErrorMessage, Field},
  props: {
    modelValue: {},
    formField: {type: Object, required: true}
  },
  emits: ['update:modelValue'],
  setup(props, {emit}) {
    const model = computed({
      get() {
        return props.modelValue
      },
      set(model) {
        emit('update:modelValue', model)
      }
    })
    return {
      model,
    }
  }
})
