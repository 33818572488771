
import {defineComponent, PropType, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import CustomFormField from "@/components/base/custom-form/CustomFormField.vue";
import FormService from "@/core/services/FormService";

export default defineComponent({
  name: "BaseCustomForm",
  components: {CustomFormField, BaseForm},
  props: {
    wizards: {type: Object as PropType<any>, required: true},
    modelData: {type: Object, required:true},
    id: {type: String}
  },
  emits: ['formSaved'],
  setup(props) {
    const step = ref(0);
    const loading = ref(false);
    const submitting = ref(false);
    const model = ref<any>(props.modelData);
    watch(() => props.modelData, cb => {
      model.value = cb;
      step.value = 0;
    })
    return {
      loading,
      step,
      submitting,
      model,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      if (this.id) {
        FormService.update(this.id, this.model).then(res => {
          this.$emit('formSaved', res);
        }).finally(() => {
          this.submitting = false;
        })
      } else {
        FormService.create(this.model).then(res => {
          this.$emit('formSaved', res);
        }).finally(() => {
          this.submitting = false;
        })
      }
    },
    onNext() {
      this.loading = true;
      FormService.validate({data: this.model.data, schema: this.modelData.schema, index: this.step}).then(() => {
        this.step += 1;
      }).finally(() => {
        this.loading = false
      })
    },
    onPrevious() {
      if (this.step > 0)
        this.step -= 1;
    }
  }
})
