import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "row mb-3" }
const _hoisted_2 = {
  key: 0,
  style: {"white-space":"pre-line"},
  class: "col-lg-12 col-form-label fw-bolder fs-6"
}
const _hoisted_3 = {
  key: 1,
  style: {"white-space":"pre-line"},
  class: "col-lg-12 col-form-label fw-bold fs-6"
}
const _hoisted_4 = { class: "col-lg-12 fv-row" }
const _hoisted_5 = ["value"]
const _hoisted_6 = {
  key: 4,
  class: "d-flex mt-3"
}
const _hoisted_7 = ["name", "value"]
const _hoisted_8 = { class: "form-check-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_DatePicker = _resolveComponent("DatePicker")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_FormErrorMessage = _resolveComponent("FormErrorMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.formField.type ==='LABEL_ONLY')
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.formField.label), 1))
      : (_openBlock(), _createElementBlock("label", _hoisted_3, _toDisplayString(_ctx.formField.label), 1)),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.formField.type ==='TEXT')
        ? (_openBlock(), _createBlock(_component_Field, {
            key: 0,
            name: _ctx.formField.name,
            type: "text",
            class: "form-control form-control-solid",
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["name", "modelValue"]))
        : (_ctx.formField.type ==='TEXTAREA')
          ? (_openBlock(), _createBlock(_component_Field, {
              key: 1,
              name: _ctx.formField.name,
              as: "textarea",
              class: "form-control form-control-solid",
              modelValue: _ctx.model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event))
            }, null, 8, ["name", "modelValue"]))
          : (_ctx.formField.type ==='DATE')
            ? (_openBlock(), _createBlock(_component_DatePicker, {
                key: 2,
                modelValue: _ctx.model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
                clazz: "form-control form-control-solid "
              }, null, 8, ["modelValue"]))
            : (_ctx.formField.type ==='SELECT')
              ? (_openBlock(), _createBlock(_component_BaseSelect, {
                  key: 3,
                  data: _ctx.formField.options,
                  name: _ctx.formField.name,
                  "no-selected": true,
                  modelValue: _ctx.model,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event))
                }, {
                  default: _withCtx(({option: option}) => [
                    _createElementVNode("option", { value: option }, _toDisplayString(option), 9, _hoisted_5)
                  ]),
                  _: 1
                }, 8, ["data", "name", "modelValue"]))
              : (_ctx.formField.type ==='RADIO')
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formField.options, (item, index) => {
                      return (_openBlock(), _createElementBlock("label", {
                        class: "form-check form-check-sm form-check-custom me-5",
                        key: index
                      }, [
                        _withDirectives(_createElementVNode("input", {
                          type: "radio",
                          name: item,
                          class: "form-check-input",
                          value: item,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event))
                        }, null, 8, _hoisted_7), [
                          [_vModelRadio, _ctx.model]
                        ]),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(item), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
      (_ctx.formField.type ==='NUMBER')
        ? (_openBlock(), _createBlock(_component_Field, {
            key: 5,
            name: _ctx.formField.name,
            type: "number",
            class: "form-control form-control-solid",
            modelValue: _ctx.model,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event))
          }, null, 8, ["name", "modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_FormErrorMessage, {
        name: _ctx.formField.name
      }, null, 8, ["name"])
    ])
  ]))
}