
import {defineComponent, ref, watch} from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import {UserSelect} from "@/core/composite/composite";
import BaseMultiSelect from "@/components/base/select/BaseMultiSelect.vue";
import FileShareService from "@/core/services/FileShareService";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import ContactService from "@/core/services/ContactService";
import {IFilter} from "@/core/entity/IFilter";

export default defineComponent({
  name: "FileShareForm",
  components: {DateTimeSelect, BaseMultiSelect, BaseForm},
  props: {
    clientId: {type: String, required: true},
    data: {type: Object, required: true}
  },
  emits: ['save'],
  setup(props) {
    const model = ref(props.data)
    const submitting = ref(false);
    watch(() => props.data, cb => {
      model.value = cb;
    })
    const contacts = ref([]);
    const filter = ref<IFilter>({
      paginationPage: 1,
      paginationSize: 100,
      key: {search: '', ep: ''},
    })
    const loadContact = (clientId) => {
      if (clientId) {
        filter.value.key.companyId = clientId
        ContactService.findAll<any>(filter.value).then(res => {
          contacts.value = res.data;
        })
      } else {
        contacts.value = [];
      }

    }
    loadContact(props.clientId)
    watch(() => props.clientId, cb => {
      loadContact(cb);
    })
    const validate = {};
    return {
      contacts,
      model,
      validate,
      submitting,
      ...UserSelect(),
    }
  },
  methods: {
    formSubmit() {
      this.submitting = true;
      FileShareService.create(this.model).then(res => {
        this.$emit('save', res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
