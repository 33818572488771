
import {defineComponent, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import DocumentService from "@/core/services/DocumentService";

export default defineComponent({
  name: "DocumentNotifyForm",
  components: {UserListSelect, BaseForm},
  props: {
    documentId: {},
    clientId : {}
  },
  emits: ['submitted'],
  setup() {
    const submitting = ref(false);
    const model = ref({userIds: []})
    return {
      model,
      submitting,
    }
  },
  methods: {
    formSubmit() {
      this.submitting = true;
      DocumentService.notify(this.documentId, this.model).then(() => {
        this.$emit('submitted')
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
