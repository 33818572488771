
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import {CLAIM_DOC_STATUS} from "@/core/config/Constant";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import ClaimService from "@/core/services/ClaimService";

export default defineComponent({
  name: "DocumentClaimStatusForm",
  components: {BaseRadio, BaseForm},
  props: {
    area: {type: String},
    claimId: {type: String},
    status: {type: String},
  },
  emits: ['saved'],
  setup(props) {
    const model = ref({status: props.status, area: props.area});
    const submitting = ref(false);
    watch(() => props.status, cb => {
      model.value.status = cb;
      model.value.area = props.area;
    })

    watch(() => props.area, cb => {
      model.value.area = cb;
      model.value.status =props.status;
    })
    return {
      submitting,
      model,
      CLAIM_DOC_STATUS,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.model.area = this.area
      ClaimService.updateClaimDoc(this.claimId, this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
