
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "ClaimDocStatus",
  props: {
    status: {},
    prefix: {required: false, default: ''}
  },
  setup(props) {
    const data = ref({clazz: '', name: ''})
    const buildStatus = (status) => {

      switch (status) {
        case 'NOT_REQUESTED':
          data.value = {clazz: 'dark', name: 'Not Requested'}
          break
        case 'PENDING':
          data.value = {clazz: 'primary', name: 'Pending'}
          break
        case 'COMPLETED':
          data.value = {clazz: 'success', name: 'Completed'}
          break
        case 'NOT_PROVIDED':
          data.value = {clazz: 'warning', name: 'Not provided'}
          break
      }
    }
    watch(() => props.status, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.status)
    return {
      data
    }
  }
})
