import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "far fa-envelope fa-2x"
}
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type ==='MSG')
    ? (_openBlock(), _createElementBlock("i", _hoisted_1))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.type !=='UNKNOW')
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              alt: "Icon",
              src: _ctx.src,
              width: "30"
            }, null, 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
}