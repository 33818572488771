
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Swal from "sweetalert2";
import NoteService from "@/core/services/NoteService";

export default defineComponent({
  name: "Notes",
  components: {QuickAction, DateTimeFormat, Avatar},
  props: {
    id: {type: String, required: true},
    type: {type: String, required: true},
    actions: {default: true}
  },
  setup(props) {
    const refId = ref(props.id);
    const refType = ref(props.type)
    const filterObject = ref({activityId: '', documentId: '', providerId: '', paymentId: ''})
    switch (refType.value) {
      case 'ACTIVITY':
        filterObject.value.activityId = refId.value
        break
      case 'DOCUMENT':
        filterObject.value.documentId = refId.value
        break
      case 'PROVIDER':
        filterObject.value.providerId = refId.value
        break
      case 'PAYMENT':
        filterObject.value.paymentId = refId.value
        break
    }
    const notePage = computed(() => store.state.NoteModule.notePage)
    return {
      ...LoadFilterObjects(Actions.LOAD_NOTES, filterObject.value, ['user']),
      notePage,
      filterObject
    }
  },
  watch: {
    id(objectId) {
      switch (this.type) {
        case 'ACTIVITY':
          this.filterObject.activityId = objectId;
          break
        case 'DOCUMENT':
          this.filterObject.documentId = objectId;
          break
        case 'PROVIDER':
          this.filterObject.providerId = objectId;
          break
        case 'PAYMENT':
          this.filterObject.paymentId = objectId;
          break
      }
      this.updateFilterObject(this.filterObject)
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await NoteService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }
})
