
import {computed, defineComponent, ref} from "vue";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import Pagination from "@/components/base/paging/Pagination.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Swal from "sweetalert2";
import FileShareService from "@/core/services/FileShareService";

export default defineComponent({
  name: "FileShares",
  components: {DateTimeFormat, Avatar, Pagination},
  props: {
    documentId: {}
  },
  setup(props) {
    const filterObjects = ref({fileId: props.documentId})
    const page = computed<any>(() => store.state.FileShareModule.page);
    return {
      filterObjects,
      ...LoadFilterObjects(Actions.LOAD_FILE_SHARES, filterObjects.value, ["sharer", 'contact']),
      page,
    }
  },
  watch: {
    documentId(cb) {
      this.filterObjects.fileId = cb;
      this.updateFilterObject(this.filterObjects);
    }
  },
  methods: {
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await FileShareService.delete(id).then(() => {
            this.paginationLoad()
          })
        }
      })
    }
  }
})
