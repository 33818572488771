
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "DocumentIcon",
  props: {
    type: {type: String}
  },
  setup(props) {
    const src = ref('')
    const build = (type) => {
      switch (type) {
        case 'PDF':
          src.value = '/media/svg/files/pdf.svg'
          break
        case 'WORD' :
          src.value = '/media/svg/files/doc.svg'
          break
        default:
          src.value = '/media/svg/files/default.svg'
      }
    }
    build(props.type)
    watch(() => props.type, (cb) => {
      build(cb);
    })
    return {
      src
    }
  }
})
