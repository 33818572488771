
import {defineComponent, ref} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import DocumentService from "@/core/services/DocumentService";

export default defineComponent({
  name: "DocumentConfirm",
  components: {BaseRadio, BaseForm},
  props: {
    documentId: {}
  },
  emits: ['confirmed'],
  setup() {
    const model = ref({decision: 'ACCEPTED', decisionComment: ''});
    const submitting = ref(false);
    return {
      model,
      submitting,
    }
  },
  methods: {
    submit() {
      this.submitting = true;
      DocumentService.confirm(this.documentId, this.model).then(res => {
        this.$emit('confirmed', res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
