
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "AuditAction",
  props: {
    action: {type: String}
  },
  setup(props) {
    const actionVal = ref('Created')
    const build = (act) => {
      switch (act) {
        case 'CREATE':
          actionVal.value = 'Created'
          break;
        case 'UPDATE':
          actionVal.value = 'Updated'
          break
        case 'UPLOADED_FILE':
          actionVal.value = 'Uploaded file'
          break
        case 'ADD':
          actionVal.value = 'Added'
          break
        case 'DEACTIVATED':
          actionVal.value = 'Deactivated'
          break

      }
    }
    build(props.action)
    return {
      actionVal
    }
  }
})
