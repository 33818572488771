
import { watch, ref, defineComponent } from 'vue'

export default defineComponent({
  name: "Panel",
  props: {
    customHeader: {required: false, default: false},
    show: {required: true, default: false},
    name: {required: false, default: ''}
  },
  data() {
    return {
      isShow: false,
    }
  },
  setup(props) {
    const label = ref(props.name);
    watch(() => props.name, (val) => {
      label.value = val
    });
    return {
      label
    }
  },
  emits: ['close'],
  methods:{
    close() {
      console.log("close")
    }
  }
})
