import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueDatePicker = _resolveComponent("VueDatePicker")!

  return (_openBlock(), _createBlock(_component_VueDatePicker, {
    name: _ctx.name,
    uid: _ctx.name,
    id: _ctx.name,
    modelValue: _ctx.selected,
    "onUpdate:modelValue": [
      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selected) = $event)),
      _ctx.onSelect
    ],
    placeholder: "MM/dd/yyyy",
    textInput: _ctx.textInput,
    inputClassName: _ctx.clazz,
    format: `MM/dd/yyyy`,
    autoApply: "",
    onTextSubmit: _ctx.onInput,
    style: {"min-width":"130px"}
  }, null, 8, ["name", "uid", "id", "modelValue", "onUpdate:modelValue", "textInput", "inputClassName", "onTextSubmit"]))
}