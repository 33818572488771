
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "Area",
  props: {
    area: {type: String, required: true}
  },
  setup(props) {
    const data = ref()

    const buildStatus = (area) => {
      switch (area) {
        case 'CM':
          data.value = 'Case Management: '
          break
        default:
          data.value = '';
      }
    }
    watch(() => props.area, (cb) => {
      buildStatus(cb)
    })
    buildStatus(props.area)
    return {
      data,
    }
  }
})
