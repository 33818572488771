
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "DocumentDecisionStatus",
  props: {
    status: {required: true}
  },
  setup(props) {

    const data = ref({name: '', clazz: ''})
    const buildLabel = (status) => {
      switch (status) {
        case 'NA':
        case 'REQUESTED':
          data.value = {name: 'Requested', clazz: 'secondary'}
          break
        case 'UPLOADED':
          data.value = {name: 'Uploaded', clazz: 'primary'}
          break
        case 'ACCEPTED':
          data.value = {name: 'Accepted', clazz: 'success'}
          break
        case 'REJECTED':
          data.value = {name: 'Rejected', clazz: 'danger'}
          break
      }
    }
    buildLabel(props.status);
    watch(() => props.status, cb => {
      buildLabel(cb);
    })
    return {
      data,
    }
  }
})
