
import { defineComponent, ref, watch } from "vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import DocumentService from "@/core/services/DocumentService";
import {Field} from "vee-validate";

export default defineComponent({
  name: "FormRename",
  components: {FormErrorMessage, BaseForm, Field},
  props: {
    rename: {type: Object, required: true}
  },
  emits: ['saveRename'],
  setup(props, {emit}) {
    const submitting = ref(false);
    const model = ref({name: props.rename.name})
    watch(() => props.rename.name, (cb) => {
      model.value.name = cb;
    })
    const onSubmit = () => {
      submitting.value = true
      DocumentService.rename(props.rename.documentId, model.value).then(res => {
        emit('saveRename', res)
      }).finally(() => {
        submitting.value = false;
      })
    }
    return {
      model,
      submitting,
      onSubmit,
    }
  }
})
