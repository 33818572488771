
import {defineComponent, ref, watch} from 'vue'
import Error from "@/components/base/Error.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import BaseForm from "@/components/base/form/BaseForm.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {DOCUMENT_STATUS, DOCUMENT_TYPES} from "@/core/config/Constant";
import {Field} from "vee-validate";
import DocumentService from "@/core/services/DocumentService";
import UserListSelect from "@/components/common/UserListSelect.vue";

export default defineComponent({
  name: "ClaimUploadForm",
  components: {UserListSelect, BaseRadio, BaseForm, FormErrorMessage, BaseSelect, Error, Field},
  props: {
    documentId: {type: String},
    document: {type: Object, required: true},
  },
  emits: ['uploaded'],
  setup(props) {
    const model = ref(props.document);
    watch(() => props.document, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    const errorLogs = ref({show: false, message: ''})
    const handleFileChange = (e: any) => {
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const file = e.target.files[0]
      model.value.name = file.name;
      model.value.file = e.target.files[0];
    }
    return {
      handleFileChange,
      DOCUMENT_TYPES,
      DOCUMENT_STATUS,
      errorLogs,
      submitting,
      model,
    }
  },
  methods: {
    upload() {
      this.submitting = true;
      if (this.documentId) {
        DocumentService.edit(this.documentId, this.model).then(res => {
          this.$emit('uploaded', res);
        }).catch(error => {
          const data = error.response.data;
          console.log(data);
          this.errorLogs.message = data.message
          this.errorLogs.show = true;
        }).finally(() => {
          this.errorLogs.show = false
          this.submitting = false;
        })
      } else {
        DocumentService.upload(this.model).then(res => {
          this.$emit('uploaded', res);
        }).catch(error => {
          const data = error.response.data;
          console.log(data);
          this.errorLogs.message = data.message
          this.errorLogs.show = true;
        }).finally(() => {
          this.errorLogs.show = false
          this.submitting = false;
        })
      }
    }
  }
})
