

import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {defineComponent, ref, watch} from "vue";

export default defineComponent({
  name: "DatePicker",
  components: {flatPickr},
  props: {
    modelValue: {
      required: true,
      default: new Date().getTime()
    },
    clazz: {required: false, default: 'form-control form-control-sm'}
  },
  setup(props) {
    const selected = props.modelValue.toString()  !== ''   ? ref(new Date(Number(props.modelValue))) : ref('');
    watch(() => props.modelValue, cb => {
      selected.value = cb ? new Date(Number(cb)) : ''
    })
    const config = {
      enableTime: false,
      allowInput: true,
      dateFormat: 'm/d/Y',
      altFormat: "DD-MM-YYYY",
    }
    return {
      config,
      selected
    }
  },
  emit: ['update:modelValue'],
  methods: {
    onSelect(e) {
      if (e[0]) {
        this.$emit('update:modelValue', e[0].getTime())
      }
    }
  }
})
